import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { useWindowWidth } from "../../services/hooks"
import { lensesInfo } from "./mockedData"
import DownCaret from "../../images/assets/Comparison/down-caret.svg"
import UpCaret from "../../images/assets/Comparison/up-caret.svg"
import WebpImage from "../WebpImage"

import "./style.scss"
import {colorTagsSlider, getHubblePricing} from '../../services/data'
import Banner from '../Banner'
import usePromo from "../../utils/usePromo";

const getBannerText = (hubblePricing) => ({
  lenses: {
    mobile: <>Save on your first Hubble Contacts order.<br/><span class='cta-button-banner'>Start Now</span></>,
    desktop: <>Save on your first Hubble Contacts order. <span class='cta-button-banner'>Start Now</span></>,
    buttonLink: "/contact-lenses/hubble",
  },
  hydro: {
    mobile: <>80% OFF your first order, just $4.99/eye.<br/><span class='cta-button-banner'>Shop Now</span></>,
    desktop: <>80% OFF your first order, just $4.99/eye. <span class='cta-button-banner'>Shop Now</span></>,
    buttonLink: "/contact-lenses/hydro-by-hubble-lenses",
  },
  hydro2: {
    mobile: <>You can now start your Hydro by Hubble subscription for just <strong>${hubblePricing.hydro.firstPrice}/eye</strong> for your first order</>,
    desktop: <>You can now start your Hydro by Hubble subscription for just <strong>${hubblePricing.hydro.firstPrice}/eye</strong> for your first order</>,
    buttonLink: "/contact-lenses/hydro-by-hubble-lenses",
  },
  frames: {
    mobile: <>Prescription eyeglasses starting at $49.99.<br/><span class='cta-button-banner'>Shop Now</span></>,
    desktop: <>Prescription eyeglasses starting at $49.99. <span class='cta-button-banner'>Shop Now</span></>,
    buttonLink: "/glasses"
  },
  astigmatism: {
    mobile: <>Now available with a Hubble subscription.<br/><span class='cta-button-banner'>Shop Now</span></>,
    desktop: <>Now available with a Hubble subscription. <span class='cta-button-banner'>Shop Now</span></>,
    buttonLink: "/contact-lenses/torics"
  },
  newPrices: {
    mobile: <>Get your first order of SkyHy or Hydro by<br/> Hubble for a special reduced price. <span className='cta-button-banner'>Shop Now</span></>,
    desktop: <>Get your first order of SkyHy or Hydro by Hubble for a special reduced price. <span className='cta-button-banner'>Shop Now</span></>,
    buttonLink: "/contact-lenses/hubble",
  }
})

const LensesCta = ({ children, product, heroBanner, homepageCta, homePageHero }) => {
  const isPromo = usePromo()
  const hubblePricing = getHubblePricing()
  const lensesProduct = isPromo && (product === "hydro" || product === "hydro2") ? lensesInfo(hubblePricing).promo : lensesInfo(hubblePricing)[product] || lensesInfo(hubblePricing).hydro
  const windowWidth = useWindowWidth()
  const bannerText = getBannerText(hubblePricing)

  return (
    <div className={`grid-section hydro-pcp-cta ${homePageHero || homepageCta ? 'homepage-v2' : ''}`} role="carousel" aria-roledescription="carousel"  aria-label={`${product} Product Carousel`}>
      {!!(homePageHero && bannerText[product] && windowWidth) &&
      <div className='banner-homepage-v2'>
        <Banner
        bannerText={bannerText[product]}
        bannerBG={colorTagsSlider[product]}
        bannerClass='homepage-v2-banner discount-banner'
        buttonLink={bannerText[product]?.buttonLink || '/'}
        clickable
      />
      </div>
      }
        <div
          className={`hydro-cta ${heroBanner ? "banner" : ""}`}
          data-type={product}
        >
          <div className={`background ${homepageCta ? 'homepage-cta' : ''}`}>
            {product === "hydro" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image hydro-image"
                  className="extra-wide-image"
                  fileName={
                    heroBanner
                      ? "Pages/Homepage/Hydro-Update-HP-Hero-desktop.png"
                      : "Pages/ContactsPCP/Hydro-by-Hubble-contact-lenses-desktop.webp"
                  }
                  alt={`${product} by Hubble Banner`}
                />
                {/* 1920px */}
                <WebpImage
                  wrapperClassName="laptop-image hydro-image"
                  className="laptop-image"
                  fileName={
                    heroBanner
                      ? "Pages/Homepage/Hydro-Update-HP-Hero-desktop.png"
                      : "Pages/ContactsPCP/Hydro-by-Hubble-contact-lenses-desktop.webp"
                  }
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit="cover"
                  wrapperClassName="tablet-image hydro-image"
                  className="tablet-image"
                  fileName={
                    heroBanner
                      ? "Pages/Homepage/Hydro-Update-HP-Hero-mobile.png"
                      : "Pages/ContactsPCP/Hydro-by-Hubble-contact-lenses-mobile.webp"
                  }
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit={!(homePageHero || homepageCta || heroBanner) ? 'cover' : 'contain'}
                  wrapperClassName="mobile-image hydro-image"
                  className="mobile-image"
                  fileName={
                    heroBanner
                      ? "Pages/Homepage/Hydro-Update-HP-Hero-mobile.png"
                      : "Pages/ContactsPCP/Hydro-by-Hubble-contact-lenses-mobile.webp"
                  }
                  alt={`${product} by Hubble Banner`}
                />
                {/* 450px */}
              </>
            )}
            {product === "hydro2" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image hydro-image"
                  className="extra-wide-image"
                  fileName="PCP/hydro.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 1920px */}
                <WebpImage
                  wrapperClassName="laptop-image hydro-image"
                  className="laptop-image"
                  fileName="PCP/hydro.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit="cover"
                  wrapperClassName="tablet-image hydro-image"
                  className="tablet-image"
                  fileName="PCP/hydro.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit={!(homePageHero || homepageCta || heroBanner) ? 'cover' : 'contain'}
                  wrapperClassName="mobile-image hydro-image"
                  className="mobile-image"
                  fileName="PCP/hydro-mobile.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 450px */}
              </>
            )}
            {product === "skyhy" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image"
                  className="extra-wide-image"
                  fileName="Pages/Homepage/SkyHy-by-Hubble-HP-Hero-Desktop.webp"
                  alt={`${product} by Hubble Banner`}
                />{" "}
                {/* 1920px */}
                <WebpImage
                  wrapperClassName="laptop-image"
                  className="laptop-image"
                  fileName="Pages/ContactsPCP/SkyHy-by-Hubble-contact-lenses-desktop.webp"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit="cover"
                  wrapperClassName="mobile-image"
                  className="mobile-image"
                  fileName={
                    heroBanner
                      ? "Pages/Homepage/SkyHy-by-Hubble-HP-Hero-Mobile.webp"
                      : "Pages/ContactsPCP/SkyHy-by-Hubble-contact-lenses-mobile.webp"
                  }
                  alt={`${product} by Hubble Banner`}
                />
                {/* 450px */}
              </>
            )}
            {product === "lenses" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image lenses-image"
                  className="extra-wide-image"
                  fileName="Pages/Homepage/Hubble-HP-Contacts-Desktop.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 1920px */}
                <WebpImage
                  wrapperClassName="laptop-image lenses-image"
                  objectFit="contain"
                  className="laptop-image"
                  fileName="Pages/Homepage/Hubble-HP-Contacts-Desktop.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  wrapperClassName="tablet-image lenses-image"
                  objectFit="contain"
                  className="tablet-image"
                  fileName="Pages/Homepage/Hubble-HP-Contacts-Mobile.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit="contain"
                  wrapperClassName="mobile-image lenses-image"
                  className="mobile-image"
                  fileName="Pages/Homepage/Hubble-HP-Contacts-Mobile.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 450px */}
              </>
            )}
            {product === "frames" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image frames-image"
                  className="extra-wide-image"
                  fileName="Pages/Homepage/Hubble-HP-Eyeglasses-Desktop.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 1920px */}\
                <WebpImage
                  wrapperClassName="laptop-image frames-image"
                  objectFit="cover"
                  className="laptop-image"
                  fileName="Pages/Homepage/Hubble-HP-Eyeglasses-Desktop.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  wrapperClassName="tablet-image frames-image"
                  objectFit="contain"
                  className="tablet-image"
                  fileName="Pages/Homepage/Hubble-HP-Eyeglasses-Mobile.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit="contain"
                  wrapperClassName="mobile-image frames-image"
                  className="mobile-image"
                  fileName="Pages/Homepage/Hubble-HP-Eyeglasses-Mobile.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 450px */}
              </>
            )}
            {product === "newPrices" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image new-prices-image"
                  className="extra-wide-image"
                  fileName="Pages/Homepage/Hydro-SkyHy-by-Hubble-HP-Hero.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 1920px */}
                <WebpImage
                  wrapperClassName="laptop-image new-prices-image"
                  objectFit="contain"
                  className="laptop-image"
                  fileName="Pages/Homepage/Hydro-SkyHy-by-Hubble-HP-Hero.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  wrapperClassName="tablet-image new-prices-image"
                  objectFit="contain"
                  className="tablet-image"
                  fileName="Pages/Homepage/Hydro-SkyHy-by-Hubble-HP-Hero.png"
                  alt={`${product} by Hubble Banner`}
                />
                <WebpImage
                  objectFit="contain"
                  wrapperClassName="mobile-image new-prices-image"
                  className="mobile-image"
                  fileName="Pages/Homepage/Hydro-SkyHy-by-Hubble-HP-Hero.png"
                  alt={`${product} by Hubble Banner`}
                />
                {/* 450px */}
              </>
            )}
            {product === "astigmatism" && (
              <>
                <WebpImage
                  wrapperClassName="extra-wide-image astigmatism-image"
                  className="extra-wide-image"
                  fileName="Pages/Homepage/Hubble-HP-Astigmatism-Desktop.jpg"
                  alt={`${product} Banner`}
                />
                {/* 1920px */}\
                <WebpImage
                  wrapperClassName="laptop-image astigmatism-image"
                  objectFit="cover"
                  className="laptop-image"
                  fileName="Pages/Homepage/Hubble-HP-Astigmatism-Desktop.jpg"
                  alt={`${product} Banner`}
                />
                <WebpImage
                  wrapperClassName="tablet-image astigmatism-image"
                  objectFit="cover"
                  className="tablet-image"
                  fileName="Pages/Homepage/Hubble-HP-Astigmatism-Mobile.jpg"
                  alt={`${product} Banner`}
                />
                <WebpImage
                  objectFit="cover"
                  wrapperClassName="mobile-image astigmatism-image"
                  className="mobile-image"
                  fileName="Pages/Homepage/Hubble-HP-Astigmatism-Mobile.jpg"
                  alt={`${product} Banner`}
                />
                {/* 450px */}
              </>
            )}
          </div>
          <p className={`sub-title ${homepageCta ? 'homepage-cta' : ''} ${isPromo ? "promo" : ""}`}>{lensesProduct.sub_title}</p>
          <h2 className={`title ${product} ${isPromo ? "promo" : ""}`}>
            {isPromo && product === "hydro" ? null
            : product === "skyhy" ? (
              <p>
                Sky<span>Hy</span>
              </p>
            ) : product === "lenses" ? (
              <p>
                Start now for
                <br />
                as little as $1.
              </p>
            ) : product === "frames" ? (
              lensesProduct?.title
            ) : product === "hydro" ? (
              `Now $${hubblePricing.hydro.firstPrice}`
            ) : product === "newPrices" ? (
              <p>{lensesProduct?.title}</p>
            ) : product === "astigmatism" ? (
              "Contacts for Astigmatism"
            ) : (
              null
            )}
          </h2>
          {(homePageHero || homepageCta)
          ? <>
              <p
                className="cta-text v2 mobile"
                dangerouslySetInnerHTML={{ __html: lensesProduct?.cta_text_mobile}}
              />
              <p
                className="cta-text v2 desktop"
                dangerouslySetInnerHTML={{ __html: lensesProduct.cta_text }}
              />
            </>
            : <p
              className="cta-text v1"
              dangerouslySetInnerHTML={{ __html: lensesProduct.cta_text }}
            />
          }
          <a
            href={lensesProduct?.link}
            className={`btn btn-primary cta-btn ${product}`}
          >
            {lensesProduct.btn_text}
          </a>
        </div>
        {children || null}
      </div>
  )
}

export const LensesCtaAdvantages = ({ product }) => {
  const hubblePricing = getHubblePricing()
  const productAdvantages =
    product === "skyhy"
      ? lensesInfo(hubblePricing).skyhy.advantages
      : lensesInfo(hubblePricing).hydro.advantages
  const [active, setActive] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()
  const containerRef = useRef(null)

  useEffect(() => {
    setIsMobile(windowWidth < 767)
  }, [windowWidth])

  useEffect(() => {
    if (active) {
      containerRef.current.setAttribute("style", "transform: translate(0, 0);")
    }
  }, [active])

  const handleMoreBtnClick = () => {
    if (active) {
      containerRef.current.removeAttribute("style")
      setTimeout(() => {
        setActive(prev => !prev)
      }, 700)
      return
    }
    setActive(prev => !prev)
  }

  return (
    <>
      {isMobile && (
        <div className="more-btn" onClick={handleMoreBtnClick}>
          Learn More
          <span className="caret-wrapper">
            {active ? <UpCaret /> : <DownCaret />}
          </span>
        </div>
      )}
      {((isMobile && active) || !isMobile) && (
        <div className="container cta-text m-column" ref={containerRef}>
          {productAdvantages.map(({ title, text }) => {
            return (
              <div className="text-container">
                <p className="cta-title">{title}</p>
                <p className="cta-copy">{text}</p>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default LensesCta
